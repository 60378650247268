i {
  font-family: "Material Icons Round";
  display: inline-block;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;

  vertical-align: top;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  -moz-font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
}
